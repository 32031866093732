<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment pt-2" >
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title mb-3" >
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="careerPbaAdd" class="mb-3">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template >
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="careerPbaObjList && careerPbaObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="careerPbaObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(carr_field)="data">
                    <p v-html="data.item.carr_field">
                    </p>
                    <button class="btn actionIcon font-size-20" variant="mr-1 mb-1" @click="careerPbaEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </button>
                    <button class="btn actionIcon" @click="showCareerPbaDeleteDialog(data.item)">
                      <i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i>
                    </button>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelCareerPbaAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <CareerPbaAdd :propOpenedInModal="true" @emitCloseCareerPbaAddModal="closeCareerPbaAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerPbaAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerPbaAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelCareerPbaEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <CareerPbaEdit :propOpenedInModal="true" :propCareerPbaObj="careerPbaEditObj" @emitCloseCareerPbaEditModal="closeCareerPbaEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerPbaEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerPbaEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelCareerPbaDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCareerPbaDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="careerPbaDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<style lang="scss" scoped>
.iq-card{
  padding: 20px 0px;
}
.iq-card-header{
  padding-bottom: 10px;
}
</style>

<script>
import { CareerPbas } from "../../../FackApi/api/careerPba.js"
import CareerPbaAdd from "./CareerPbaAdd"
import CareerPbaEdit from "./CareerPbaEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "CareerPbaList",
  components: {
    CareerPbaAdd,
    CareerPbaEdit
  },
  data () {
    return {
      cvCardTitle: "Career Profile Building Activities",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Career Profile Building Activity",
      cvAddModalHeader: "Add Career Profile Building Activity",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Profile Building Activity List Response",
      showModelCareerPbaAdd: false,
      showModelCareerPbaEdit: false,
      showModelCareerPbaDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "Career Name", key: "carr_field", class: "text-left align-text-top", sortable: true },
        { label: "Career Profile Building Activity Type", key: "carr_pba_type", class: "text-left align-text-top", sortable: true },
        { label: "Career Profile Building Activity Meta", key: "carr_pba_meta", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      careerPbaObjList: null,
      careerPbaEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  mounted () {
    this.careerPbaList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * careerPbaList
     */
    async careerPbaList () {
      try {
        let careerPbaListResp = await CareerPbas.careerPbaList(this)
        if (careerPbaListResp.resp_status) {
          this.careerPbaObjList = careerPbaListResp.resp_data.data
          this.totalRows = careerPbaListResp.resp_data.count
        }
        else {
          this.toastMsg = careerPbaListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerPbaList() and Exception:", err.message)
      }
    },
    /**
     * careerPbaAdd
     */
    careerPbaAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerPba_add")
        }
        else {
          this.showModelCareerPbaAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerPbaAdd() and Exception:", err.message)
      }
    },
    /**
     * careerPbaEdit
     */
    careerPbaEdit (type, item) {
      try {
        this.careerPbaEditObj = item
        this.$router.push("/admin/careerPba_edit/" + this.careerPbaEditObj.carr_pba_id)
      }
      catch (err) {
        console.error("Exception occurred at careerPbaEdit() and Exception:", err.message)
      }
    },
    /**
     * showCareerPbaDeleteDialog
     */
    showCareerPbaDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCareerPbaDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCareerPbaDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * careerPbaDelete
     */
    async careerPbaDelete () {
      try {
        let careerPbaDelResp = await CareerPbas.careerPbaDelete(this, this.delObj.carr_pba_id)
        await ApiResponse.responseMessageDisplay(this, careerPbaDelResp)
        if (careerPbaDelResp && !careerPbaDelResp) {
          this.showModelCareerPbaDelete = false
          return false
        }
        let index = this.careerPbaObjList.indexOf(this.delObj)
        this.careerPbaObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCareerPbaDelete = false
      }
      catch (err) {
        console.error("Exception occurred at careerPbaDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCareerPbaAddModal
     */
    closeCareerPbaAddModal (careerPbaAddData) {
      try {
        if (careerPbaAddData) {
          if (this.careerPbaObjList && this.careerPbaObjList.length >= 1) {
            let carrpbaObjLength = this.careerPbaObjList.length
            let lastId = this.careerPbaObjList[carrpbaObjLength - 1]["id"]
            careerPbaAddData.id = lastId + 1
          }
          else {
            this.careerPbaObjList = []
            careerPbaAddData.id = 11111
          }
          careerPbaAddData.created_on = moment()
          this.careerPbaObjList.unshift(careerPbaAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelCareerPbaAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecareerPbaAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCareerPbaEditModal
     */
    closeCareerPbaEditModal () {
      try {
        this.showModelCareerPbaEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCareerPbaEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
